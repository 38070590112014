<ion-app>
  <ion-header>
    <div class="offline-popup" *ngIf="onlineStateService.isOffline$ | async">
      <p>{{ 'offlineEvent' | translate }}</p>
    </div>
  </ion-header>
  <ion-router-outlet></ion-router-outlet>
  <ion-footer *ngIf="showMenu$ | async" class="ion-no-border" [class.home-page]="router.isActive('/tasks', true)" [ngClass]="platformService.isIos ? 'ios-app' : ''">
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col class="ion-text-center menu-btn-tasks">
          <div class="ion-align-items-center ion-justify-content-center">
            <ion-icon src="/assets/icon/home.svg"
                      class="icon home"
                      [ngClass]="{selected: router.isActive('/tasks', true) || router.isActive('/people', false)}"
                      routerLink="/tasks">
            </ion-icon>
          </div>
        </ion-col>
        <ion-col class="ion-text-center menu-btn-my-tasks">
          <div class="ion-align-items-center ion-justify-content-center">
            <ion-icon name="briefcase-outline"
                      class="icon search"
                      [ngClass]="{selected: requestedUrl === '/tasks/my-tasks' }"
                      routerLink="/tasks/my-tasks">
            </ion-icon>
          </div>
        </ion-col>
        <ion-col class="ion-text-center">
          <div class="ion-align-items-center ion-justify-content-center notifications-slot">
            <ion-icon name="notifications-outline"
                      class="icon notification"
                      [ngClass]="{selected: router.isActive('/notifications', false) || requestedUrl === '/notifications' }"
                      routerLink="/notifications">
            </ion-icon>
            <span class="no-read-messages" *ngIf="notificationService.countNotifications"></span>
          </div>
        </ion-col>
        <!--<ion-col class="ion-text-center">
          <div class="ion-align-items-center ion-justify-content-center">
            <ion-icon src="/assets/icon/dashboard.svg"
                      class="icon dashboard"
                      [ngClass]="{selected: router.isActive('/dashboard', false)}"
                      routerLink="/dashboard">
            </ion-icon>
          </div>
        </ion-col>-->
        <ion-col class="ion-text-center">
          <div class="ion-align-items-center ion-justify-content-center chat-slot">
            <ion-icon src="/assets/icon/chat.svg"
                      class="icon chat"
                      [ngClass]="{selected: router.isActive('/chats', false) || requestedUrl === '/chats'}"
                      routerLink="/chats">
            </ion-icon>
            <span class="no-read-messages" *ngIf="noReadMessages"></span>
          </div>
        </ion-col>
        <ion-col class="ion-text-center">
          <div class="ion-align-items-center ion-justify-content-center">
            <ion-icon src="/assets/icon/user.svg"
                      class="icon profile"
                      [ngClass]="{selected: router.isActive('/profile', false) || requestedUrl === '/profile'}"
                      routerLink="/profile">
            </ion-icon>
          </div>
        </ion-col>
        <ion-col class="ion-text-center">
          <div class="ion-align-items-center ion-justify-content-center">
            <ion-icon src="/assets/icon/earth_globe.svg"
                      class="icon avatar"
                      [ngClass]="{selected: router.isActive('/avatar', false) || requestedUrl === '/avatar'}"
                      routerLink="/avatar"
                      (click)="openConsoleModal()">
            </ion-icon>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>

  <div id="downloadPrompt">
    <div class="downloadPrompt__description">
      <p *ngIf="platformService.isAndroid" class="downloadPrompt__text">{{ 'common.downloadAppPrompt.Android' | translate }}</p>
      <p *ngIf="platformService.isIos" class="downloadPrompt__text">{{ 'common.downloadAppPrompt.IOS' | translate }}</p>
      <button id="closePrompt" class="close" (click)="hideDownloadPrompt()">
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.49902 7.99902L20.5001 20.0001" stroke="#1A1C1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.49902 20.001L20.5001 7.99992" stroke="#1A1C1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <ion-button (click)="downloadApp()" class="btn-download">{{ 'common.downloadAppPrompt.button' | translate }}</ion-button>
  </div>

  <div *ngIf="availableTips.length > 0 && currentHint !== null" class="hint-bg"></div>
  <div *ngIf="availableTips.length > 0 && currentHint !== null" class="hint" style="opacity: 0">
    <div class="slider">
      <div class="tips-swiper">
        <div class="swiper-pagination tips-pagination"></div>
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let hint of availableTips; let i = index">
            <div class="close-btn" (click)="onCloseHintClick()">
              <img src="../assets/icon/close.svg" alt="close">
            </div>
            <h5 class="header" [class.top-offset]="availableTips.length > 1">{{ 'tips.' + hint.name + '.header' | translate }}</h5>
            <div class="text">{{ 'tips.' + hint.name + '.text' | translate }}</div>
            <ion-button *ngIf="hint.ok" class="next-tip" color="dark" shape="round">{{ hint.ok }}</ion-button>
            <ion-button *ngIf="hint.ok && currentHint === availableTips.length - 1" class="close-tip" color="dark" shape="round"
                        (click)="onCloseHintClick()">{{ hint.ok }}
            </ion-button>
          </div>
        </div>
        <div class="swiper-button-next" [class.hidden]="availableTips[currentHint] && availableTips[currentHint].ok">
          <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.1786 11.9285L2.42861 20.6785C2.18204 20.9251 1.84762 21.0636 1.49892 21.0636C1.15022 21.0636 0.8158 20.9251 0.569232 20.6785C0.322664 20.432 0.184143 20.0976 0.184143 19.7489C0.184143 19.4002 0.322664 19.0657 0.569232 18.8192L8.39064 11L0.57142 3.17855C0.449332 3.05646 0.352485 2.91152 0.286412 2.752C0.220338 2.59249 0.18633 2.42152 0.18633 2.24886C0.18633 2.0762 0.220338 1.90523 0.286412 1.74572C0.352485 1.5862 0.449332 1.44126 0.57142 1.31917C0.693508 1.19708 0.838448 1.10024 0.997964 1.03416C1.15748 0.96809 1.32845 0.934082 1.50111 0.934082C1.67377 0.934082 1.84473 0.96809 2.00425 1.03416C2.16377 1.10024 2.30871 1.19708 2.43079 1.31917L11.1808 10.0692C11.303 10.1913 11.3999 10.3363 11.466 10.4959C11.532 10.6555 11.5659 10.8266 11.5657 10.9993C11.5655 11.1721 11.5312 11.3431 11.4648 11.5025C11.3984 11.662 11.3011 11.8068 11.1786 11.9285Z"
              fill="white"/>
          </svg>
        </div>
        <div class="swiper-button-prev" [class.hidden]="availableTips[currentHint] && availableTips[currentHint].ok">
          <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.4286 18.8214C11.6752 19.0679 11.8137 19.4024 11.8137 19.7511C11.8137 20.0998 11.6752 20.4342 11.4286 20.6807C11.182 20.9273 10.8476 21.0658 10.4989 21.0658C10.1502 21.0658 9.8158 20.9273 9.56923 20.6807L0.819231 11.9307C0.696871 11.8088 0.599786 11.6639 0.533541 11.5044C0.467297 11.3448 0.433197 11.1738 0.433197 11.0011C0.433197 10.8283 0.467297 10.6573 0.533541 10.4977C0.599786 10.3382 0.696871 10.1933 0.819231 10.0714L9.56923 1.32137C9.8158 1.0748 10.1502 0.936279 10.4989 0.936279C10.8476 0.936279 11.182 1.0748 11.4286 1.32137C11.6752 1.56794 11.8137 1.90236 11.8137 2.25106C11.8137 2.59976 11.6752 2.93417 11.4286 3.18074L3.60939 11L11.4286 18.8214Z"
              fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ion-app>